import React, { FunctionComponent } from 'react'
import { PageProps, graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Icon,
  List,
  ListItem,
  ListIcon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { BiAlarm, BiBook, BiBookOpen, BiCheck, BiGroup } from 'react-icons/bi'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { WpSimpleProduct } from '../../../graphql-types'
import { CourseStructure } from '../../components/courseStructure'
import { ProductList } from '../../components/productList'
import { GroupEnquiry } from '../../components/groupEnquiry'
import moment from 'moment'

interface IDataProps {
  wpProduct: WpSimpleProduct
}

const ProductTemplate: FunctionComponent<PageProps<IDataProps>> = ({
  data,
}) => {
  const {
    wpProduct: { courseDetails, id, name },
  } = data
  const breakdown =
    courseDetails?.presentationMethod === 'Online'
      ? courseDetails?.courseType?.courseStructure?.breakdownOnline
      : courseDetails?.courseType?.courseStructure?.breakdownOffline
  const resources =
    courseDetails?.presentationMethod === 'Online'
      ? courseDetails?.courseType?.courseStructure?.resourcesOnline
      : courseDetails?.courseType?.courseStructure?.resourcesOffline
  const subjects =
    courseDetails?.presentationMethod === 'Online'
      ? courseDetails?.courseType?.courseStructure?.subjectsOnline
      : courseDetails?.courseType?.courseStructure?.subjectsOffline

  return (
    <Layout>
      <SEO
        title={
          courseDetails?.courseType?.title ? courseDetails.courseType.title : ''
        }
      />
      <Helmet>
        <script type="application/ld+json">
          {`
          {
              '@context': 'http://schema.org',
              '@type': 'Event',
              'eventAttendanceMode': '${
                courseDetails?.presentationMethod === 'Online'
                  ? 'https://schema.org/OnlineEventAttendanceMode'
                  : 'https://schema.org/OfflineEventAttendanceMode'
              }',
              'name': '${name}',
              'startDate': '${
                courseDetails?.sessions && courseDetails?.sessions[0]
                  ? moment(courseDetails?.sessions[0].startTime).format(
                      'YYYY-MM-DD'
                    )
                  : ''
              }',
              'endDate': '${
                courseDetails?.sessions &&
                courseDetails?.sessions[courseDetails?.sessions?.length - 1]
                  ? moment(
                      courseDetails?.sessions[
                        courseDetails?.sessions?.length - 1
                      ]!.startTime
                    ).format('YYYY-MM-DD')
                  : ''
              }',
              'location': [{
                '@type': '${
                  courseDetails?.presentationMethod === 'Online'
                    ? 'VirtualLocation'
                    : courseDetails?.locationShortname
                }',
                'url': '${data.wpProduct.link}',
              },
              'description': '',
              'offers': {
                '@type': 'Offer',
                'url': '${data.wpProduct.link}',
                'price': '${data.wpProduct.price}',
                'priceCurrency': 'GBP',
                'availability': 'https://schema.org/InStock'
              },
              'organizer': {
                '@type': 'Organization',
                'name': 'The Balance Group',
                'url': 'https://www.thebalancegroup.co.uk'
              }
          }
        `}
        </script>
      </Helmet>

      <Container maxW="4xl">
        <ProductList
          hasBooking={courseDetails?.bookingType !== 'group'}
          hasDetails={false}
          isHero={true}
          isLast={true}
          {...data.wpProduct}
        />

        <Grid gridGap="12">
          <Box>
            <Grid
              gridGap="4"
              gridTemplateColumns={['1fr', '1fr 1fr', '1fr', '1fr 1fr']}
              mb="8"
              mt="12"
            >
              <Box
                backgroundColor={useColorModeValue(
                  'whiteAlpha.900',
                  'whiteAlpha.100'
                )}
                borderRadius="4"
                gridColumnEnd={['2', '3', '2', '3']}
                gridColumnStart={['1']}
                p="4"
              >
                <Grid gridGap="4">
                  {breakdown?.map((item, i) => {
                    return (
                      <Grid
                        alignItems="center"
                        gridColumnGap="2"
                        gridRowGap="1"
                        gridTemplateColumns="auto 1fr"
                        gridTemplateRows={item?.text ? 'auto auto' : 'auto'}
                        key={i}
                      >
                        <Icon
                          as={item?.icon === 'BiAlarm' ? BiAlarm : BiGroup}
                          w={8}
                          h={8}
                        />{' '}
                        {/* todo: map strings to svgs */}
                        <Heading size="md">{item?.heading}</Heading>
                        {item?.text ? (
                          <Text gridColumnStart="2">{item?.text}</Text>
                        ) : null}
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
              <Box
                backgroundColor={useColorModeValue(
                  'whiteAlpha.900',
                  'whiteAlpha.100'
                )}
                borderRadius="2"
                p="4"
              >
                <Grid gridGap="4">
                  <Grid
                    alignItems="center"
                    gridGap="2"
                    gridTemplateColumns="auto 1fr"
                  >
                    <Icon as={BiBookOpen} w={8} h={8} />
                    <Heading size="md">Subjects</Heading>
                  </Grid>
                  <List>
                    {subjects?.map((item, i) => {
                      return (
                        <ListItem key={i}>
                          <Grid
                            alignItems="center"
                            gridTemplateColumns="auto 1fr"
                          >
                            <ListIcon as={BiCheck} color="green.500" />
                            {item?.subject}
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </Grid>
              </Box>
              <Box
                backgroundColor={useColorModeValue(
                  'whiteAlpha.900',
                  'whiteAlpha.100'
                )}
                borderRadius="2"
                p="4"
              >
                <Grid gridGap="4">
                  <Grid
                    alignItems="center"
                    gridGap="2"
                    gridTemplateColumns="auto 1fr"
                  >
                    <Icon as={BiBook} w={8} h={8} />
                    <Heading size="md">Resources</Heading>
                  </Grid>
                  <List>
                    {resources?.items?.map((item, i) => {
                      return (
                        <ListItem key={i}>
                          <Grid
                            alignItems="center"
                            gridTemplateColumns="auto 1fr"
                          >
                            <ListIcon as={BiCheck} color="green.500" />
                            {item?.resource}
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                  <Text fontSize="sm" fontWeight="bold">
                    {resources?.text}
                  </Text>
                </Grid>
              </Box>
            </Grid>
            <Grid gridGap="4">
              <Heading size="lg">Course Structure</Heading>
              <CourseStructure {...courseDetails} />
            </Grid>
          </Box>
          <Box>
            {courseDetails?.bookingType === 'group' ? (
              <GroupEnquiry {...data.wpProduct} />
            ) : (
              <Grid gridGap="4">
                <Heading size="lg">Book your place</Heading>
                <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
                  <Grid gridGap="4">
                    <ProductList
                      hasDetails={false}
                      isFoot={true}
                      {...data.wpProduct}
                    />
                    <Flex justifyContent="flex-end">
                      <Button
                        color="msuGreen.500"
                        colorScheme="aqua"
                        minW="32"
                        onClick={() => {
                          navigate(
                            `/products?course=${courseDetails?.courseType?.id}`
                          )
                        }}
                        variant="outline"
                      >
                        Find more dates
                      </Button>
                    </Flex>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ProductTemplate

export const query = graphql`
  query wpProduct($id: String) {
    wpProduct(id: { eq: $id }) {
      databaseId
      id
      link
      name
      ... on WpSimpleProduct {
        price
        onSale
        salePrice
        regularPrice
        stockQuantity
      }
      courseDetails {
        sessions {
          duration
          startTime
        }
        courseType {
          ... on WpCourse {
            courseStructure {
              breakdownOffline {
                icon
                text
                heading
              }
              breakdownOnline {
                icon
                text
                heading
              }
              resourcesOffline {
                items {
                  resource
                }
                text
              }
              resourcesOnline {
                items {
                  resource
                }
                text
              }
              sessionsOffline {
                items {
                  item
                }
                duration
                instructed
                name
                session
              }
              sessionsOnline {
                items {
                  item
                }
                duration
                instructed
                name
                session
              }
              subjectsOffline {
                subject
              }
              subjectsOnline {
                subject
              }
            }
            id
            slug
            title
          }
        }
        groupBookings {
          freePlaces
          maximum
          minimum
        }
        instructor {
          ... on WpInstructor {
            id
            title
          }
        }
        bookingType
        locationShortname
        presentationMethod
      }
    }
  }
`
