import React, { FunctionComponent } from 'react'
import moment from 'moment'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Grid,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { BiCheck } from 'react-icons/bi'
import { WpProduct_Coursedetails } from '../../../graphql-types'

export const CourseStructure: FunctionComponent<WpProduct_Coursedetails> = ({
  courseType,
  sessions: sessionTimes,
  presentationMethod,
}) => {
  const sessionsList =
    presentationMethod === 'Online'
      ? courseType?.courseStructure?.sessionsOnline
      : courseType?.courseStructure?.sessionsOffline

  return (
    <Accordion allowToggle>
      {sessionsList?.map((session, i) => {
        return (
          <AccordionItem key={i}>
            <AccordionButton>
              <Box flex="1" mr="2" textAlign="left">
                <Grid gridTemplateColumns={['auto', '1fr auto']}>
                  <Heading size="md">{session?.name}</Heading>
                  {session?.duration ? (
                    <Text size="md">
                      {session?.session && sessionTimes ? (
                        <Text as="span" fontWeight="bold">{`${moment(
                          sessionTimes[session.session - 1]?.startTime
                        ).format('Do MMM H:mma')}${' - '}${
                          session?.session && sessionTimes
                            ? moment(
                                sessionTimes[session.session - 1]?.startTime
                              )
                                .add(session?.duration, 'minutes')
                                .format('H:mma')
                            : ''
                        }`}</Text>
                      ) : (
                        <Text as="span">{`${session?.duration / 60} ${
                          session?.duration / 60 === 1 ? 'hr' : 'hrs'
                        }`}</Text>
                      )}
                    </Text>
                  ) : null}
                </Grid>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <List lineHeight="tall">
                {session?.items?.map((item, i) => {
                  return (
                    <ListItem key={i}>
                      <Grid alignItems="center" gridTemplateColumns="auto 1fr">
                        <ListIcon as={BiCheck} color="msuGreen" />
                        {item?.item}
                      </Grid>
                    </ListItem>
                  )
                })}
              </List>
            </AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
