import React, { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { boolean, number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Stack,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Textarea,
  UnorderedList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { WpSimpleProduct } from '../../../graphql-types'
import { handleEnquiry } from '../../api/wp-client'

const schema = object().shape({
  fname: string()
    .matches(/^[A-Za-z-' ]*$/, {
      message: 'Please enter letters only, no special characters or digits',
      excludeEmptyString: false,
    })
    .required('Please enter your first name'),
  lname: string()
    .matches(/^[A-Za-z-' ]*$/, {
      message: 'Please enter letters only, no special characters or digits',
      excludeEmptyString: false,
    })
    .required('Please enter your last name'),
  email: string().email().required('Please enter your email address'),
  enquiry: string().required('Please enter your enquiry'),
})

const initialValues = {
  fname: '',
  lname: '',
  email: '',
  enquiry: '',
}

export const GroupEnquiry: FunctionComponent<WpSimpleProduct> = ({
  courseDetails: {
    courseType: { title },
    groupBookings: { freePlaces, maximum, minimum },
    presentationMethod,
  },
  price,
}) => {
  const {
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const toast = useToast()
  const [groupSize, setGroupSize] = useState(minimum)
  const [delegates, setDelegates] = useState({
    paying: minimum,
    free: 0,
    total: minimum,
  })
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleGroupChange = (val: number) => {
    const free = freePlaces
      .split(',')
      .filter((test: number) => test < val).length

    setGroupSize(val)
    setDelegates({ paying: val - free, free, total: val })
  }

  const onSubmit = async (values: any): Promise<void> => {
    const { fname, lname, email, enquiry } = values

    try {
      const response = await handleEnquiry({
        course: title,
        delegates: delegates.total.toString(),
        email,
        enquiry,
        fname,
        lname,
        method: presentationMethod,
      })

      const errors = response.invalid_fields.map((field) => {
        return <ListItem>{field.message}</ListItem>
      })

      toast({
        title: response.message,
        description: errors ? (
          <UnorderedList>{errors}</UnorderedList>
        ) : undefined,
        status: response.status === 'mail_sent' ? 'success' : 'error',
      })

      response.status === 'mail_sent' && setHasSubmitted(true)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error with your enquiry',
        status: 'error',
      })
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid gridGap="4">
        <Heading size="lg">Create your course</Heading>
        <Grid
          backgroundColor={useColorModeValue(
            'whiteAlpha.700',
            'whiteAlpha.100'
          )}
          gridGap="4"
          gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
        >
          <Box borderRadius="2" p="4">
            <Grid gridGap="2">
              <Text mb="4">
                Our group bookings can be tailored to meet your individual needs
              </Text>
              <Heading size="md">Pricing</Heading>
              <Text>
                Select your required number of delegates to discover how many
                free places you can claim
              </Text>
              <Grid
                gridGap="8"
                gridTemplateColumns="auto 1fr auto"
                px="4"
                py="8"
              >
                <Text>{minimum}</Text>
                <Slider
                  aria-label=""
                  colorScheme="msuGreen"
                  isDisabled={hasSubmitted}
                  min={minimum}
                  max={maximum}
                  onChange={(val) => handleGroupChange(val)}
                  value={groupSize}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={4} />
                </Slider>
                <Text>{maximum}</Text>
              </Grid>
              <Table>
                <Tbody>
                  <Tr>
                    <Td>{`${delegates.paying} Delegates`}</Td>
                    <Td>x</Td>
                    <Td>{price}</Td>
                  </Tr>
                  {delegates.free > 0 ? (
                    <Tr>
                      <Td>{`${delegates.free} ${
                        delegates.free > 1 ? 'Delegates' : 'Delegate'
                      }`}</Td>
                      <Td>x</Td>
                      <Td>FREE</Td>
                    </Tr>
                  ) : null}
                  <Tr>
                    <Td fontWeight="bold">{`${delegates.total} Delegates`}</Td>
                    <Td>=</Td>
                    <Td fontWeight="bold">
                      {price
                        ? `£${(
                            delegates.paying *
                            Number(price.replace(/[^0-9.-]+/g, ''))
                          ).toFixed(2)}`
                        : ''}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Grid>
          </Box>
          <Box
            backgroundColor={useColorModeValue(
              'whiteAlpha.900',
              'whiteAlpha.100'
            )}
            borderRadius="2"
            p="4"
          >
            <Grid gridGap="4">
              <Box>
                <Text>
                  We'll contact you to discuss your individual requirements in
                  more detail
                </Text>
              </Box>
              <Grid gridGap="4">
                <FormControl
                  isDisabled={hasSubmitted}
                  isInvalid={!!errors.fname}
                >
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" {...register('fname')} />
                  <FormErrorMessage>{errors.fname?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isDisabled={hasSubmitted}
                  isInvalid={!!errors.lname}
                >
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" {...register('lname')} />
                  <FormErrorMessage>{errors.lname?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isDisabled={hasSubmitted}
                  isInvalid={!!errors.email}
                >
                  <FormLabel>Email</FormLabel>
                  <Input type="text" {...register('email')} />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isDisabled={hasSubmitted}
                  isInvalid={!!errors.enquiry}
                >
                  <FormLabel>Enquiry</FormLabel>
                  <Textarea rows={10} {...register('enquiry')} />
                  <FormErrorMessage>{errors.enquiry?.message}</FormErrorMessage>
                </FormControl>
                <Button
                  color="msuGreen.500"
                  colorScheme="aqua"
                  disabled={hasSubmitted}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {hasSubmitted ? 'Enquiry submitted' : 'Submit enquiry'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
