import axios, { AxiosInstance } from 'axios'

let apiClient: AxiosInstance

const createApiClient = () => {
  const headers: Record<string, string> = {}

  apiClient = axios.create({
    baseURL: process.env.GATSBY_WP_URL,
    headers,
  })

  apiClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return apiClient
}

apiClient = createApiClient()

export const handleEnquiry = async ({
  course,
  delegates,
  email,
  enquiry,
  fname,
  lname,
  method,
}: any): Promise<{
  contact_form_id: number
  invalid_fields: any
  message: string
  status: string
}> => {
  let formData = new FormData()
  formData.append('course', course)
  formData.append('delegates', delegates)
  formData.append('email', email)
  formData.append('enquiry', enquiry)
  formData.append('fname', fname)
  formData.append('lname', lname)
  formData.append('method', method)

  const resp = await apiClient.post<{
    contact_form_id: number
    invalid_fields: any
    message: string
    status: string
  }>('/wp-json/contact-form-7/v1/contact-forms/859/feedback', formData, {
    validateStatus: (statusCode_1: number) => statusCode_1 === 200,
  })

  return resp.data
}
